import { cloneElement, Children } from 'react';
import { bool, number, node } from 'prop-types';
import { Transition } from 'react-transition-group';

const createDefaultStyle = (duration) => ({
  visibility: 'hidden',
  opacity: 0,
  transition: `opacity ${duration}ms ease`,
  willChange: 'opacity',
});

const transitionStyles = {
  entering: { opacity: 0, visibility: undefined },
  entered: { opacity: 1, visibility: undefined },
  exiting: { opacity: 0, visibility: undefined },
};

const Fade = ({ in: inProp, duration = 150, children }) => (
  <Transition in={inProp} timeout={duration}>
    {(state) =>
      cloneElement(Children.only(children), {
        style: {
          ...children.props.style,
          ...createDefaultStyle(duration),
          ...transitionStyles[state],
        },
      })
    }
  </Transition>
);

Fade.propTypes = {
  in: bool,
  duration: number,
  children: node.isRequired,
};

export default Fade;
