import socketClient from './client/socket';

export const CommandType = {
  general: {
    reboot: Symbol(),
  },
  settings: {
    update: Symbol(),
  },
  posts: {
    unpublish: Symbol(),
  },
  reactions: {
    create: Symbol(),
  },
  notifications: {
    create: Symbol(),
  },
};

export function connect() {
  if (!socketClient.connected) {
    socketClient.connect();
  }
}

export function disconnect() {
  if (socketClient.connected) {
    socketClient.disconnect();
  }
}

export function onReceive(callback) {
  socketClient.on('settings', handleSettingsChange);
  socketClient.on('unPublished', handlePostUnpublished);
  socketClient.on('refreshDisplays', handleRebootRequest);
  socketClient.on('reactionCreated', handleReactionCreated);
  socketClient.on('notify', handleNotify);

  function handleRebootRequest() {
    callback({ type: CommandType.general.reboot });
  }

  function handleSettingsChange(newSettings) {
    callback({ type: CommandType.settings.update, payload: newSettings });
  }

  function handlePostUnpublished(unpublishedPost) {
    callback({ type: CommandType.posts.unpublish, payload: unpublishedPost });
  }

  function handleReactionCreated(reaction) {
    callback({ type: CommandType.reactions.create, payload: reaction });
  }

  function handleNotify(notification) {
    callback({ type: CommandType.notifications.create, payload: notification });
  }

  function unsubscribe() {
    socketClient.off('settings', handleSettingsChange);
    socketClient.off('unPublished', handlePostUnpublished);
    socketClient.off('refreshDisplays', handleRebootRequest);
    socketClient.off('reactionCreated', handleReactionCreated);
    socketClient.off('notify', handleNotify);
  }

  return unsubscribe;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  connect,
  disconnect,
  onReceive,
};
