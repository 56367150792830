import { useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { setTokenProvider } from 'api/token';
import LoginInit from 'components/LoginInit';
import LoginCallback from 'components/LoginCallback';
import Screen from 'pages/Screen';
import Redirect from 'pages/Redirect';
import config from './config';

const oktaAuth = new OktaAuth(config.oidc);

setTokenProvider(() => {
  return oktaAuth.getAccessToken();
});

const isKioskEnabled = () => {
  return window.location.host.startsWith('kiosk') || window.location.pathname.includes('kiosk');
};

function App() {
  const [kiosk, setKiosk] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setKiosk(isKioskEnabled());
  }, [location.pathname]);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {kiosk ? (
        <Switch>
          <Route path="/">
            <Screen kiosk />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route path="/login/callback">
            <LoginCallback />
          </Route>

          <Route path="/login/init">
            <LoginInit />
          </Route>

          <Route exact path="/kiosk">
            <Screen kiosk />
          </Route>

          <Route path="/redirect">
            <Redirect />
          </Route>

          <Route path="/">
            <Screen />
          </Route>
        </Switch>
      )}
    </Security>
  );
}

export default App;
