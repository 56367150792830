export async function getAdapter() {
  if ('safari' in window && 'pushNotification' in window.safari) {
    return import('./adapters/apn');
  } else if ('Notification' in window) {
    return import('./adapters/fcm');
  }
  return {
    getPermissionStatus: () => 'denied',
    requestPermission: () => {
      /* noop */
    },
    registerForegroundMessageHandler: () => {
      /* noop */
    },
    removeForegroundMessageHandler: () => {
      /* noop */
    },
  };
}

let adapter = null;
export async function initialize() {
  adapter = await getAdapter();
  if (!adapter) {
    console.warn('Push Notifications are not supported in this browser');
  }
}

export function getPermissionStatus() {
  return adapter.getPermissionStatus();
}

export function requestPermission(userInfo, callback) {
  return adapter.requestPermission(userInfo, callback);
}

export function registerForegroundMessageHandler(handler) {
  return adapter.registerForegroundMessageHandler(handler);
}

export function removeForegroundMessageHandler(handler) {
  return adapter.removeForegroundMessageHandler(handler);
}
