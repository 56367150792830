import * as React from 'react';

const isDebugBuild = process.env.REACT_APP_DEBUG === '1';

class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    if (isDebugBuild) {
      console.error(error);
    } else {
      window.setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }

  render() {
    if (this.state.hasError && !isDebugBuild) {
      return (
        <p style={{ color: '#444444' }}>Screen will be back shortly. Reloading in 5 seconds.</p>
      );
    } else if (this.state.hasError && isDebugBuild) {
      return (
        <div>
          <p style={{ color: '#444444' }}>Screen will be back shortly. Reloading in 5 seconds.</p>
          <p style={{ color: '#444444' }}>Debugging mode is on. Screen won't reload.</p>
          <p style={{ color: '#444444' }}>{this.state.error.message}</p>
          <p style={{ color: '#444444' }}>{this.state.error.stack}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
