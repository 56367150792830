import React, { useState, useCallback } from 'react';
import isMobile from 'is-mobile';
import { CommandType } from 'api/commands';
import Condition from 'components/Condition';
import useHub from 'hooks/useHub';
import Mobile from './Mobile';
import Desktop from './Desktop';
import OfflineNotice from './components/OfflineNotice';
import UploadContainer from './components/UploadContainer';
import { useFullScreenMode } from './hooks';
import LoveScreen from 'components/LoveScreen/LoveScreen';
import LogoContainer from 'components/LogoContainer';
import styles from './index.module.css';

function Screen({ kiosk }) {
  const [settings, setSettings] = useState(null);
  const isLoaded = settings !== null;

  const handleCommand = useCallback((command) => {
    if (command.type === CommandType.settings.update) {
      setSettings(command.payload);
    }
  }, []);

  useHub(handleCommand);
  useFullScreenMode(kiosk);

  return (
    <Condition when={isLoaded}>
      <div className={styles.ScreenContainer}>
        <Condition when={isMobile()}>
          <LogoContainer kiosk={kiosk} mobile />
          <Mobile settings={settings} kiosk={kiosk} />
        </Condition>

        <Condition when={kiosk}>
          <LoveScreen />
        </Condition>

        <Condition when={!isMobile()}>
          <LogoContainer kiosk={kiosk} />
          <Desktop settings={settings} kiosk={kiosk} />
        </Condition>

        <OfflineNotice settings={settings} />

        <Condition when={!kiosk}>
          <UploadContainer />
        </Condition>
      </div>
    </Condition>
  );
}

export default Screen;
