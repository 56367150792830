import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Redirect() {
  const params = useQuery();
  const to = params.get('to');

  useEffect(() => {
    setTimeout(() => {
      window.location.href = to;
    }, 4000);
  }, [to]);

  return (
    <div style={{ color: 'black', margin: '20px' }}>
      You'll be redirected to <a href={to}>{to}</a> shortly.
    </div>
  );
}

export default Redirect;
