import { useState, useEffect } from 'react';

export default function usePointerLock() {
  const [state, setState] = useState(document.pointerLockElement !== null);

  useEffect(() => {
    function callback() {
      setState(document.pointerLockElement !== null);
    }

    document.addEventListener('pointerlockchange', callback, false);
    return () => document.removeEventListener('pointerlockchange', callback, false);
  }, []);

  function activate() {
    return document.documentElement.requestPointerLock();
  }

  function deactivate() {
    return document.exitPointerLock();
  }

  return [state, activate, deactivate];
}
