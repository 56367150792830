import * as React from 'react';
import classnames from 'classnames';
import styles from './Eyebrow.module.css';

const Eyebrow = ({ className, children, element: Element = 'span', size = 'large', ...props }) => {
  const classes = classnames(styles.Eyebrow, className, styles[size]);

  return (
    <Element className={classes} {...props}>
      {children}
    </Element>
  );
};

export { Eyebrow };
