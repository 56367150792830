import { forwardRef } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import styles from './Input.module.css';

export const Input = forwardRef((props, ref) => {
  const { error, labelText, helpText, helpIcon, variant, className, children, ...rest } = props;

  const labelClasses = classNames(styles.Label, className);
  const inputClasses = classNames(styles.Input, variant && styles[variant], error && styles.error);

  return (
    <label className={labelClasses}>
      {labelText}
      <input
        className={inputClasses}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        ref={ref}
        {...rest}
      />
      {children}
      {helpText && !error && (
        <div className={styles.HelpText}>
          {helpIcon} {helpText}
        </div>
      )}
      {error && (
        <p className={styles.Error}>
          <ErrorMessage errors={error} name={rest.name} />
        </p>
      )}
    </label>
  );
});
