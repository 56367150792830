import React, { useState, useCallback, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Flipper, Flipped } from 'react-flip-toolkit';
import cx from 'classnames';
import posts from 'api/posts';
import Condition from 'components/Condition';
import getOptimizedUrl from 'utils/media/getOptimizedUrl';
import LocationCode from './LocationCode';
import LazyVideo from './LazyVideo';
import LazyImage from './LazyImage';
import LoveButton from 'components/LoveButton/LoveButton';
import { ReactComponent as CollapseIcon } from './Collapse.svg';
import { ReactComponent as PlayIcon } from './Play.svg';
import { ReactComponent as PauseIcon } from './Pause.svg';
import { ReactComponent as VolumeIcon } from './Volume.svg';
import { ReactComponent as NoVolumeIcon } from './NoVolume.svg';
import styles from './index.module.css';

const FullscreenTile = ({ takeover, collapsedWidth, collapsedHeight, onCollapse, showLove }) => {
  const post = takeover.post;

  const { authState } = useOktaAuth();
  const [fullscreen, setFullscreen] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [complete, setComplete] = useState(false);
  const [muted, setMuted] = useState(false);

  const collapse = useCallback(() => {
    setFullscreen(false);
  }, []);

  const handleAnimationComplete = useCallback(() => {
    if (!fullscreen) {
      onCollapse(post);
    } else {
      setComplete(true);
    }
  }, [fullscreen, onCollapse, post]);

  const handleVolumeButtonClick = useCallback(() => {
    setMuted((prev) => !prev);
  }, []);

  const handlePlayButtonClick = useCallback(() => {
    setPlaying((prev) => !prev);
  }, []);

  const handleVideoEnded = useCallback(() => {
    setPlaying(false);

    // Collapse video posts automatically on end when there's no explicit takeoverDuration set
    // and takeover was activated automatically
    if (
      takeover.trigger === 'auto' &&
      post.media.resourceType === 'video' &&
      !post.takeoverDuration
    ) {
      collapse();
    }
  }, [collapse, post.media.resourceType, post.takeoverDuration, takeover.trigger]);

  // Handle ESC
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'Escape') {
        collapse();
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [collapse, post]);

  // Activate fullscreen mode on mount
  useEffect(() => {
    setFullscreen(true);
  }, []);

  // Auto collapse if image or non-default takeoverDuration and takeover was triggered automatically
  useEffect(() => {
    if (
      takeover.trigger === 'auto' &&
      (post.media.resourceType !== 'video' || !!post.takeoverDuration)
    ) {
      // Default is 10s
      const duration = post.takeoverDuration || 10000;

      const timerId = setTimeout(() => {
        collapse();
      }, duration);

      return () => clearTimeout(timerId);
    }
  }, [collapse, post, takeover.trigger]);

  const handleLikeToggle = useCallback(
    async (active) => {
      if (active) {
        const response = await posts.like(post);
        post.userReaction = response.data.id;
      } else {
        await posts.unlike(post);
        post.userReaction = null;
      }
    },
    [post]
  );

  const style = !fullscreen
    ? {
        left: takeover.x,
        top: takeover.y,
        width: collapsedWidth,
        height: collapsedHeight,
      }
    : {};

  return (
    <Flipper flipKey={fullscreen} onComplete={handleAnimationComplete}>
      <Flipped flipId="fullscreen">
        <div className={styles.FullscreenTile} style={style}>
          <div className={styles.FullscreenTileWrapper}>
            <Condition when={authState && authState.isAuthenticated && showLove}>
              <LoveButton defaultActive={!!post.userReaction} onToggle={handleLikeToggle} />
            </Condition>

            <Condition when={post.media.resourceType === 'image'}>
              <LazyImage
                src={getOptimizedUrl({
                  publicId: post.media.publicId,
                  cloudName: post.media.keyName,
                  resourceType: post.media.resourceType,
                  height: 510, // TODO: change this
                })}
              />
            </Condition>

            {/* TODO: Review autoplay policy https://developers.google.com/web/updates/2017/09/autoplay-policy-changes */}
            <Condition when={post.media.resourceType === 'video'}>
              <LazyVideo
                playing={playing}
                muted={muted}
                loop={false}
                src={getOptimizedUrl({
                  publicId: post.media.publicId,
                  format: 'mp4',
                  cloudName: post.media.keyName,
                  resourceType: post.media.resourceType,
                  height: 510, // TODO: change this
                })}
                type="video/mp4"
                poster={post.media.thumbnailUrl}
                onEnded={handleVideoEnded}
              />
            </Condition>

            <Condition when={fullscreen && complete}>
              <div className={styles.FullscreenTileHeaderWrapper}>
                <button
                  type="button"
                  className={styles.FullscreenTileDetailModeToggleBtn}
                  onClick={collapse}
                >
                  <CollapseIcon />
                </button>
              </div>

              <div className={styles.FullscreenTileContentOuterWrapper}>
                <div className={styles.FullscreenTileContent}>
                  <Condition when={post.location.code}>
                    <LocationCode
                      className={styles.FullscreenTileContent__Location}
                      code={post.location.code}
                    />
                  </Condition>

                  <div className={styles.FullscreenTileContentInnerWrapper}>
                    <Condition when={post.caption}>
                      <div
                        className={cx(styles.FullscreenTileContent__Caption, styles.ClampTwoLines)}
                      >
                        {post.caption}
                      </div>
                    </Condition>

                    <Condition when={post.author.firstName && post.author.lastName}>
                      <div
                        className={cx(styles.FullscreenTileContent__Author, styles.ClampOneLine)}
                      >
                        {post.author.firstName} {post.author.lastName}
                      </div>
                    </Condition>
                  </div>

                  <Condition when={post.media.resourceType === 'video'}>
                    <div className={styles.FullscreenTileContent__VideoIndicator}>
                      <button
                        type="button"
                        className={styles.FullscreenTilePlayButton}
                        onClick={handlePlayButtonClick}
                      >
                        <Condition when={playing}>
                          <PauseIcon />
                        </Condition>

                        <Condition when={!playing}>
                          <PlayIcon />
                        </Condition>
                      </button>

                      <button
                        type="button"
                        className={styles.FullscreenTileVolumeButton}
                        onClick={handleVolumeButtonClick}
                      >
                        <Condition when={muted}>
                          <NoVolumeIcon />
                        </Condition>

                        <Condition when={!muted}>
                          <VolumeIcon />
                        </Condition>
                      </button>
                    </div>
                  </Condition>
                </div>
              </div>
            </Condition>
          </div>
        </div>
      </Flipped>
    </Flipper>
  );
};

export default FullscreenTile;
