import React, { useRef, useCallback, forwardRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import JSConfetti from '../LoveScreen/confetti/es';
import { ReactComponent as HeartIcon } from './Heart.svg';
import { motion } from 'framer-motion';

import styles from './LoveButton.module.css';

const LoveButton = forwardRef(
  (
    { defaultActive, onToggle, className, tileWidth = '100%', tileHeight = '100%', ...props },
    ref
  ) => {
    const [active, setActive] = useState(defaultActive);
    const rewardRef = useRef(null);
    const jsConfetti = useRef(null);
    const img = useRef(null);
    const classes = classnames(styles.LoveButton, className, active && styles.active);

    useEffect(() => {
      jsConfetti.current = new JSConfetti({ canvas: rewardRef.current });
      img.current = new Image();
      img.current.src = '/ConfettiHeart.png';
    }, []);

    const handleLove = useCallback(
      (e) => {
        e.stopPropagation();
        const newActive = !active;
        if (!active) {
          jsConfetti.current.addConfetti({
            images: [
              {
                src: img.current,
                width: 50,
                height: 50,
              },
            ],
            confettiNumber: 50,
          });
        }
        setActive(newActive);
        onToggle(newActive);
      },
      [active, onToggle]
    );

    return (
      <React.Fragment>
        <canvas
          className={styles.LoveCanvas}
          ref={rewardRef}
          width={tileWidth}
          height={tileHeight}
          style={{ width: tileWidth, height: tileHeight }}
        ></canvas>
        <div ref={ref} className={styles.LoveButtonWrapper}>
          <motion.button
            whileTap={{
              scale: 0.9,
              y: 5,
              transition: { type: 'spring', stiffness: 200, damping: 2 },
            }}
            type="button"
            onClick={(e) => handleLove(e)}
            className={classes}
            data-ga={active ? 'unlike-button' : 'like-button'}
            {...props}
          >
            <HeartIcon className={styles.HeartIcon} />
          </motion.button>
        </div>
      </React.Fragment>
    );
  }
);

export default LoveButton;
