import sendPostRequest from './sendPostRequest';

/**
 * Client for communicating with the Cloudinary API.
 */
export default class CloudinaryApiClient {
  /**
   * @param {Object}    params
   * @param {String}    [params.cloudName]
   * @param {String}    [params.uploadPreset]
   * @param {String}    [params.apiKey]
   * @param {String}    [params.folder]
   * @param {String}    [params.cloudName]
   * @param {String[]}  [params.tags]
   * @param {Function}  [params.createUploadRequest]
   */
  constructor({ cloudName, uploadPreset, apiKey, folder, tags, createUploadRequest }) {
    this.cloudName = cloudName;
    this.uploadPreset = uploadPreset;
    this.apiKey = apiKey;
    this.folder = folder;
    this.tags = tags;
    this.createUploadRequest = createUploadRequest;
  }

  /**
   * Uploads a file to Cloudinary.
   *
   * @param {File|Blob} file
   * @param {Object}    options
   * @param {Function}  [options.onUploadProgress] Accepts one ProgressEvent argument
   * @returns {Promise<Object>} JSON response data
   */
  async upload(file, { onUploadProgress = undefined }) {
    const options = {
      cloud_name: this.cloudName,
      apiKey: this.apiKey,
      upload_preset: this.uploadPreset,
      folder: this.folder,
      tags: this.tags && this.tags.join(','),
      timestamp: new Date().getTime(),
    };

    const request = await this.createUploadRequest(file, options);

    if (!request) {
      throw new Error('Could not generate a request');
    }

    const responseText = await sendPostRequest(
      request.url,
      { ...request.payload, file },
      {
        onUploadProgress,
      }
    );

    return JSON.parse(responseText);
  }
}
