import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import sortBy from 'lodash/sortBy';
import LocationCode from 'pages/Screen/components/Tile/LocationCode';
import { Eyebrow, Input, Select, Button } from 'components/ui';
import Condition from 'components/Condition/index';
import users from 'api/users';
import locationsApi from 'api/locations';
import getOptimizedUrl from 'utils/media/getOptimizedUrl';
import LazyVideo from '../../Tile/LazyVideo';
import LazyImage from '../../Tile/LazyImage';
import styles from './CaptionEditorPanel.module.css';

const CaptionEditorPanel = ({ post, submitAction, file, onInputFocused }) => {
  const [user, setUser] = useState({});
  const [updateLocation, setUpdateLocation] = useState(false);
  const [locations, setLocations] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState({});

  const { register, watch, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      location: post.location ?? defaultLocation.id,
    },
  });

  const watchFields = watch();

  const fetchUser = useCallback(async () => {
    const me = await users.me();
    setUser(me.data);
  }, []);

  const fetchLocations = useCallback(async () => {
    let response = await locationsApi.listAll();
    setLocations(sortBy(response.data, 'location'));
    setDefaultLocation(response.data.find((loc) => loc.code === 'HQ'));
    reset({
      location: response.data.find((loc) => loc.code === 'HQ').id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUser();
    fetchLocations();
  }, [fetchUser, fetchLocations]);

  const handleUpdatePost = async (data) => {
    // handle submission here
    submitAction(data);
  };

  // TODO: Do something...better... than this lol.

  const cloudName = file.url.split('/')[3];

  return (
    <div className={styles.CaptionPanel}>
      <div className={styles.CaptionPanelInner}>
        <div className={styles.CaptionPanelText}>
          <Eyebrow element="h3" size="small">
            Remember
          </Eyebrow>
          <ul>
            <li>
              The Collective is a space for all of #CarvanaCountry to share and celebrate together
            </li>
            <li>Don't be a Richard, Be sure to keep things friendly and clean</li>
            <li>
              All posts are moderated, so keep an eye out for an email with the status of your post{' '}
            </li>
          </ul>
        </div>

        <form
          className={styles.CaptionForm}
          id="caption-form"
          onSubmit={handleSubmit(handleUpdatePost)}
        >
          <Input
            className={styles.CaptionInput}
            type="text"
            placeholder="Enter caption"
            {...register('caption', {
              required: 'This field is required',
              maxLength: { value: 128, message: 'Please keep it under 128 characters' },
            })}
            error={formState.errors}
            onFocus={() => onInputFocused(true)}
            onBlur={() => onInputFocused(false)}
          />

          <Condition when={!updateLocation}>
            <Button variant="link" onClick={() => setUpdateLocation(true)}>
              Edit post location
            </Button>
          </Condition>

          <Condition when={updateLocation}>
            <Select
              className={styles.Input}
              options={locations.map((option, index) => ({
                value: option.id,
                optionText: option.location,
              }))}
              {...register('location')}
            />
          </Condition>
        </form>
      </div>
      <div className={styles.CaptionPanelImage}>
        <Eyebrow element="h3" size="small">
          Preview
        </Eyebrow>
        <div className={styles.ImageFrameWrapper}>
          <figure className={styles.ImageFrame}>
            <Condition when={file.resource_type === 'image' && file.format !== 'gif'}>
              <LazyImage
                src={getOptimizedUrl({
                  publicId: file.public_id,
                  cloudName: cloudName,
                  resourceType: file.resource_type,
                  height: 300,
                })}
              />
            </Condition>

            <Condition when={file.resource_type === 'image' && file.format === 'gif'}>
              <LazyImage
                src={getOptimizedUrl({
                  publicId: file.public_id,
                  cloudName: cloudName,
                  resourceType: file.resource_type,
                  height: 300,
                  format: 'gif',
                })}
              />
            </Condition>

            {/* TODO: Review autoplay policy https://developers.google.com/web/updates/2017/09/autoplay-policy-changes */}
            <Condition when={file.resource_type === 'video'}>
              <LazyVideo
                playing={true}
                src={getOptimizedUrl({
                  publicId: file.public_id,
                  format: 'mp4',
                  cloudName: cloudName,
                  resourceType: file.resource_type,
                  height: 300,
                })}
                type="video/mp4"
                poster={file.thumbnailUrl}
              />
            </Condition>
            <figcaption className={styles.ImageCaption}>
              <Condition when={watchFields.location || user.location}>
                <LocationCode
                  className={styles.Location}
                  code={
                    watchFields.location
                      ? locations.find((loc) => loc.id === watchFields.location)?.code
                      : user.location?.code
                  }
                />
              </Condition>
              <Condition when={!watchFields.location && !user.location}>
                <LocationCode className={styles.Location} code={defaultLocation?.code} />
              </Condition>

              <div className={styles.FigureText}>
                {watchFields.caption}
                <cite>
                  {user.firstName} {user.lastName}
                </cite>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};

export { CaptionEditorPanel };
