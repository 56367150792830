import * as React from 'react';
import styles from './OktaError.module.css';

const OktaError = ({ error }) => {
  if (error.name && error.message) {
    return (
      <p className={styles.Root}>
        <strong>{error.name}</strong>: {error.message}
      </p>
    );
  }
  return <p className={styles.Root}>Error: {error.toString()}</p>;
};

export default OktaError;
