import * as React from 'react';
import cx from 'classnames';
import styles from './index.module.css';

// TODO hold off rendering the image until it loads fully
const LazyImage = (props) => (
  <img className={cx(styles.Img, styles['Img--Fit'])} alt="" {...props} />
);

export default LazyImage;
