import noop from 'lodash/noop';

export const log = process.env.NODE_ENV === 'development' ? console.log : noop;
export const openGroup = process.env.NODE_ENV === 'development' ? console.groupCollapsed : noop;
export const closeGroup = process.env.NODE_ENV === 'development' ? console.groupEnd : noop;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  log,
  openGroup,
  closeGroup,
};
