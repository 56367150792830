import { Cloudinary } from 'cloudinary-core';
import getDevicePixelRatio from './getDevicePixelRatio';

const cloudinary = new Cloudinary({
  secure: true,
});

const getOptimizedUrl = ({
  publicId,
  cloudName,
  resourceType,
  height,
  width,
  format,
  crop = 'scale',
}) =>
  cloudinary.url(publicId, {
    cloud_name: cloudName,
    type: 'upload',
    resource_type: resourceType,
    crop,
    height,
    width,
    dpr: getDevicePixelRatio(),
    quality: 'auto',
    fetch_format: 'auto',
    format,
  });

export default getOptimizedUrl;
