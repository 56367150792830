// NOTE:
// The following formulas produces inaccurate results for some videos.
// we need to figure out why.

export function determineWidth(sourceWidth, sourceHeight, targetHeight) {
  return Math.floor((sourceWidth / sourceHeight) * targetHeight * 10) / 10;
}

export function determineHeight(sourceWidth, sourceHeight, targetWidth) {
  return Math.floor(((targetWidth * sourceHeight) / sourceWidth) * 10) / 10;
}

function resize(sourceWidth, sourceHeight, targetWidth, targetHeight) {
  if (typeof sourceWidth !== 'number' || typeof sourceHeight !== 'number') {
    throw new Error('Source `width` and `height` must be provided.');
  }

  if (
    (typeof targetWidth !== 'number' && typeof targetHeight !== 'number') ||
    (typeof targetWidth === 'number' && typeof targetHeight === 'number')
  ) {
    throw new Error('One (and only) of the target media `width` or `height` should be provided.');
  }

  return targetHeight
    ? [determineWidth(sourceWidth, sourceHeight, targetHeight), targetHeight]
    : [targetWidth, determineHeight(sourceWidth, sourceHeight, targetWidth)];
}

export default resize;
