let _tokenProvider = () => null;

export const setTokenProvider = (tokenProvider) => {
  _tokenProvider = tokenProvider;
};

export const getAccessToken = () => {
  const provideToken = _tokenProvider;
  return provideToken();
};
