import httpClient from './client/http';
import { createLocationFromJson } from './mappers';

export async function listAll() {
  const response = await httpClient.get(
    `${process.env.REACT_APP_REST_API_URL}/v1/screen/locations`
  );

  return {
    data: response.locations.map(createLocationFromJson),
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  listAll,
};
