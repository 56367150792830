import { useState, useEffect } from 'react';

export default function useFullScreen() {
  const [state, setState] = useState(document.fullscreenElement !== null);

  useEffect(() => {
    function callback() {
      setState(document.fullscreenElement !== null);
    }

    document.addEventListener('fullscreenchange', callback, false);
    return () => document.removeEventListener('fullscreenchange', callback, false);
  }, []);

  function activate() {
    return document.documentElement.requestFullscreen();
  }

  function deactivate() {
    return document.exitFullscreen();
  }

  return [state, activate, deactivate];
}
