import { useCallback } from 'react';
import JSConfetti from './confetti/es';
import { CommandType } from 'api/commands';
import useHub from 'hooks/useHub';

const jsConfetti = new JSConfetti();
const img = new Image();
img.src = '/ConfettiHeart.png';

const LoveScreen = ({ className }) => {
  const dropConfetti = (quantity) => {
    jsConfetti.addConfetti({
      images: [
        {
          src: img,
          width: 50,
          height: 50,
        },
      ],
      confettiNumber: quantity,
    });
  };

  const handleCommand = useCallback((command) => {
    if (command.type === CommandType.reactions.create) {
      dropConfetti(3);
    }
  }, []);

  useHub(handleCommand);

  return null;
};

export default LoveScreen;
