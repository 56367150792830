import * as React from 'react';
import debounce from 'lodash/debounce';
import commands, { CommandType } from 'api/commands';
import { determineWidth } from 'utils/media/resize';
import PostManager from './PostManager';
import Tile from './components/Tile';
import FullscreenTile from './components/FullscreenTile';
import Shifter from './components/Shifter';
import Condition from 'components/Condition';

const fromSeconds = (seconds) => seconds * 1000;

const POST_SCHEDULER_INTERVAL = fromSeconds(9);
const SUPERPOST_SCHEDULER_INTERVAL = fromSeconds(55);

function computeLineHeight(containerHeight) {
  return containerHeight / 2;
}

function computeLineWidth(containerWidth) {
  return containerWidth;
}

/**
 * This function appends as many posts as is necessary to fill up an entire width of the row
 * accounting for the case when the first post exits the screen so that the condition
 * still holds after the change.
 */
function fillRow(rowPosts, postManager, rowWidth, rowHeight) {
  if (postManager.getSize() === 0) {
    return rowPosts;
  }

  // Fill in at least one post when empty
  const newRowPosts = [...rowPosts];
  if (newRowPosts.length === 0) {
    newRowPosts.push(postManager.dequeue());
  }

  const currentTotalWidth = rowPosts.reduce(
    (acc, post) => acc + determineWidth(post.media.width, post.media.height, rowHeight),
    0
  );
  const neededItemsWidth =
    rowWidth +
    determineWidth(newRowPosts[0].media.width, newRowPosts[0].media.height, rowHeight) -
    currentTotalWidth;

  let addedWidth = 0;
  while (postManager.getSize() > 0 && neededItemsWidth - addedWidth >= 0) {
    const post = postManager.dequeue();
    newRowPosts.push(post);
    addedWidth += determineWidth(post.media.width, post.media.height, rowHeight);
  }

  return newRowPosts;
}

// TODO:
// break down this large component into several smaller ones
// leverage existing hooks and possibly rewrite the state management
// to use useReducer hook as it fits the use case nicely

export default class DesktopScreen extends React.Component {
  postManager = new PostManager();

  // We keep track of any "Event Emitter" subscriptions
  // so that we can cancel them all on unmount.
  subscriptions = [];

  state = {
    // Screen Configuration
    settings: null,

    line1Posts: [],
    line2Posts: [],

    // TODO: should be moved to the Shifter
    line1Transitioning: false,
    line2Transitioning: false,

    // TODO: this is essentially a derived state which may not be necessary
    // Line size is used to compute the # of resources needed
    lineHeight: computeLineHeight(window.innerHeight),
    lineWidth: computeLineWidth(window.innerWidth),

    // The post that is taking over the whole screen
    takeover: null,
  };

  async componentDidMount() {
    this.subscriptions.push(commands.onReceive(this.handleCommand));
    window.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('resize', this.handleWindowResize);

    // init sequence
    this.postManager.setMaxSize(this.props.settings.queueLimit);
    await this.postManager.syncSuperPosts();
    await this.postManager.syncRecentPosts();

    this.setState({
      line1Posts: fillRow(
        this.state.line1Posts,
        this.postManager,
        this.state.lineWidth,
        this.state.lineHeight
      ),
      line2Posts: fillRow(
        this.state.line2Posts,
        this.postManager,
        this.state.lineWidth,
        this.state.lineHeight
      ),
    });

    if (this.props.settings.active) {
      this.startTimers();
    }
  }

  componentDidUpdate(prevProps) {
    this.postManager.setMaxSize(prevProps.settings.queueLimit);

    if (prevProps.settings.active !== this.props.settings.active) {
      if (!this.props.settings.active) {
        this.stopTimers();
      } else if (this.props.settings.active && !this.state.takeover) {
        this.startTimers();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('resize', this.handleWindowResize);
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.stopTimers();
  }

  startTimers() {
    if (this.timersRunning || document.hidden) {
      return;
    }

    this.postSchedulerIntervalId = setInterval(this.postSchedulerTick, POST_SCHEDULER_INTERVAL);
    this.superpostSchedulerIntervalId = setInterval(
      this.superpostSchedulerTick,
      SUPERPOST_SCHEDULER_INTERVAL
    );

    this.timersRunning = true;
  }

  postSchedulerTick = () => {
    this.postManager.syncRecentPosts();

    // TODO:
    // There's prolly a bug hiding here that would cause screen to suspend the transitions
    // if we have exactly 1.5 screen of posts but the probability of running into it
    // is extremely low given we'll have seeder data.

    if (
      this.postManager.getSize() > 0 &&
      // When the browser is left idle and the computer goes into sleep mode
      // all transitions are suspended until after the computer wakes up.
      // This is how we detect such a state and stop shifting items.
      // Consider https://stackoverflow.com/a/4080174/596625
      !this.state.line1Transitioning &&
      !this.state.line2Transitioning
    ) {
      this.setState({
        line1Transitioning: true,
        line2Transitioning: true,
      });
    }
  };

  superpostSchedulerTick = () => {
    this.postManager.syncSuperPosts();
  };

  stopTimers() {
    clearInterval(this.postSchedulerIntervalId);
    this.postSchedulerIntervalId = null;

    clearInterval(this.superpostSchedulerIntervalId);
    this.superpostSchedulerIntervalId = null;

    this.timersRunning = false;
  }

  activateTakeoverIfAvailable() {
    const { line1Transitioning, line1Posts, line2Transitioning, line2Posts } = this.state;

    // Wait for both lines to stop transitions
    if (line1Transitioning || line2Transitioning) {
      return;
    }

    const takeoverCandidates = [];

    if (line1Posts[0] && line1Posts[0].takeoverMode === 'auto') {
      takeoverCandidates.push({ post: line1Posts[0], x: 0, y: 0, trigger: 'auto' });
    }

    if (line2Posts[0] && line2Posts[0].takeoverMode === 'auto') {
      takeoverCandidates.push({
        post: line2Posts[0],
        x: 0,
        y: window.innerHeight / 2,
        trigger: 'auto',
      });
    }

    if (takeoverCandidates.length > 0) {
      this.stopTimers();

      // Pick a winner randomly if there's more than 1
      const winner = Math.floor(Math.random() * Math.floor(takeoverCandidates.length)) % 2;
      this.setState({ takeover: takeoverCandidates[winner] });
    }
  }

  handleLineTransitionEnd = (lineNumber) => {
    const prevPosts = [...this.state[`line${lineNumber}Posts`]];
    const exitingPost = prevPosts.shift();
    const nextPosts = fillRow(
      prevPosts,
      this.postManager,
      this.state.lineWidth,
      this.state.lineHeight
    );

    // superposts require special treatment
    if (!exitingPost.isSuperPost) {
      this.postManager.requeue(exitingPost);
    }

    this.setState(
      {
        [`line${lineNumber}Transitioning`]: false,
        [`line${lineNumber}Posts`]: nextPosts,
      },
      () => {
        this.activateTakeoverIfAvailable();
      }
    );
  };

  handleLine1TransitionEnd = () => {
    this.handleLineTransitionEnd(1);
  };

  handleLine2TransitionEnd = () => {
    this.handleLineTransitionEnd(2);
  };

  // Remote commands handler
  handleCommand = (command) => {
    switch (command.type) {
      case CommandType.general.reboot:
        window.location.reload();
        break;
      case CommandType.posts.unpublish:
        this.handlePostUnpublish(command.payload);
        break;
      default:
        // noop
        break;
    }
  };

  handlePostUnpublish = (unpublishedPost) => {
    // attempt to remove from the queue
    const removedPostsCount = this.postManager.remove(unpublishedPost);

    // update the state when the queue length hasn't changed
    // (means the post was found in the queue and therefore is definitely not on the screen)
    if (removedPostsCount === 0) {
      // remove immediately if on screen and fill in the gap
      this.setState({
        line1Posts: fillRow(
          this.state.line1Posts.filter((post) => post.id !== unpublishedPost.id),
          this.postManager,
          this.state.lineWidth,
          this.state.lineHeight
        ),
        line2Posts: fillRow(
          this.state.line2Posts.filter((post) => post.id !== unpublishedPost.id),
          this.postManager,
          this.state.lineWidth,
          this.state.lineHeight
        ),
      });
    }
  };

  // All animations and transitions are suspended when the tab
  // is inactive but the timers keep running which results in
  // a staggered transition upon returning to a previously
  // inactive tab. So we pause timers when the window or tab
  // become inactive.
  handleVisibilityChange = () => {
    if (document.hidden) {
      this.stopTimers();
    } else if (!this.state.takeover) {
      this.startTimers();
    }
  };

  handleWindowResize = debounce(
    () => {
      const lineHeight = computeLineHeight(window.innerHeight);
      const lineWidth = computeLineWidth(window.innerWidth);

      this.setState({
        lineHeight,
        lineWidth,

        // Fill in more items shall we need to
        line1Posts: fillRow(this.state.line1Posts, this.postManager, lineWidth, lineHeight),
        line2Posts: fillRow(this.state.line2Posts, this.postManager, lineWidth, lineHeight),
      });
    },
    150,
    { leading: true, trailing: true }
  );

  handleCollapsePost = (_post) => {
    this.setState({ takeover: null });
    this.startTimers();
  };

  handleExpandPost = (post, { x, y }) => {
    this.stopTimers();
    this.setState({ takeover: { post, x, y, trigger: 'manual' } });
  };

  render() {
    const { kiosk } = this.props;
    const { takeover, line1Transitioning, line1Posts, line2Transitioning, line2Posts, lineHeight } =
      this.state;

    // TODO: try Flip animation and see if it can shift tiles

    // TODO: move this functionality inside "Shifter
    const carousel1Position =
      line1Transitioning && line1Posts.length > 0
        ? -1 * determineWidth(line1Posts[0].media.width, line1Posts[0].media.height, lineHeight)
        : 0;

    // TODO: move this functionality inside "Shifter"
    const carousel2Position =
      line2Transitioning && line2Posts.length > 0
        ? -1 * determineWidth(line2Posts[0].media.width, line2Posts[0].media.height, lineHeight)
        : 0;

    return (
      <React.Fragment>
        <Shifter
          position={carousel1Position}
          transitioning={line1Transitioning}
          onTransitionEnd={this.handleLine1TransitionEnd}
        >
          {line1Posts.map((item) => (
            <Shifter.Item key={item.id}>
              <Tile
                post={item}
                width={determineWidth(item.media.width, item.media.height, lineHeight)}
                height={lineHeight}
                suspend={!!takeover || line1Transitioning}
                onExpand={this.handleExpandPost}
                showLove={!kiosk}
              />
            </Shifter.Item>
          ))}
        </Shifter>

        <Shifter
          position={carousel2Position}
          transitioning={line2Transitioning}
          onTransitionEnd={this.handleLine2TransitionEnd}
          delay={0.1}
        >
          {line2Posts.map((item) => (
            <Shifter.Item key={item.id}>
              <Tile
                post={item}
                width={determineWidth(item.media.width, item.media.height, lineHeight)}
                height={lineHeight}
                suspend={!!takeover || line2Transitioning}
                onExpand={this.handleExpandPost}
                showLove={!kiosk}
              />
            </Shifter.Item>
          ))}
        </Shifter>

        <Condition when={takeover}>
          <FullscreenTile
            takeover={takeover}
            collapsedWidth={determineWidth(
              takeover && takeover.post.media.width,
              takeover && takeover.post.media.height,
              lineHeight
            )}
            collapsedHeight={lineHeight}
            onCollapse={this.handleCollapsePost}
            showLove={!kiosk}
          />
        </Condition>
      </React.Fragment>
    );
  }
}
